import React from 'react';

const VisionMissionComponent = () => {
  return (
    <div className='missionVisionBody'>
    <div className="missionVision-container">
      <div className="box vision" data-aos="flip-right">
        <h2>Our Vision</h2>
        <p>To be a highly respected sawmill in producing top quality wooden products within agreed timelines to our clients.</p>
      </div>
      <div className="box mission" data-aos="flip-left">
        <h2>Our Mission</h2>
        <p>To create more employment opportunities and contribute in uplifting small businesses of the Richmond rural communities.</p>
      </div>
    </div>
    </div>
  );
}

export default VisionMissionComponent;