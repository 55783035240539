import React from 'react'
import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <div class="contact-body">
    <div class="contactUs-container">
      <h1>Contact Us</h1>
      
      <Link to="/contact"> 
      <button className="inquire-btn">Inquire</button>
    </Link>
    
      <p class="inquire-text">Inquire about the prices and more info.</p>
    </div>
  </div>
  )
}

export default ContactUs