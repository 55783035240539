import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PindiweImage from "../Images/PindiweImage2.1_BGEdRemoved.png";
import myVideo from "../New Products/Video1.mp4";
function AboutUsPage() {

  return (
    <>
      <NavBar />
   
      <div class="_container">
      <div class="Pindiwe-image" data-aos="fade-right">
        <img src={PindiweImage} alt="Pindiwe Image" />
      </div>
      <div class="about" data-aos="fade-left" style={{display:"flex"}}>
        <h1>About Us</h1>
        <p class="about-description">
        <p>Welcome to Akili Timber, your trusted sawmill located in Richmond, in the heart of KZN Midlands. Our strategic location just one hour away from Durban Harbor, is a game-changer for our customers, as it ensures swift turnarounds and hassle-free deliveries. What sets us apart is not just our location but our adaptable team. Our dedicated staff fully understand designs and possess the agility to swiftly adjust to new customer requirements. Whether it's unique specifications, custom projects, or evolving needs, our team is ready to collaborate and deliver solutions tailored to your demands. </p>
            
            <p>Akili Timber is proud to be 100% owned and managed by Pindiwe Filtane, a seasoned businesswoman. Her leadership and vision drives our commitment to ensuring the company's continued success and growth. We invite you to explore our world of timber craftsmanship and experience the quality and commitment that sets us apart. </p>
            
            </p>
      </div>
    </div>

        <div className='missionVisionBody'>
    <div className="missionVision-container">
      <div className="box vision" data-aos="flip-right">
        <h2>Our Vision</h2>
        <p>To be a highly respected sawmill in producing top quality wooden products within agreed timelines to our clients.</p>
      </div>
      <div className="box mission" data-aos="flip-left">
        <h2>Our Mission</h2>
        <p>To create more employment opportunities and contribute in uplifting small businesses of the Richmond rural communities.</p>
      </div>
    </div>
    </div>

            <div className="Manufacturing">
              <div className="video-container">
              
            <video width="700" controls controlsList="nodownload">
            <source src={myVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
            <div className="video-description">
            <h2 className="heading-video">What We Do</h2>
            <p>
                At Akili Timber, we pride ourselves on our extensive experience and commitment to quality. Our greatest strength lies in our skilled personnel, many of whom are residents of the local community. We are dedicated to supporting the local economy and currently collaborate with over 10 eucalyptus and pine suppliers in the area. This partnership benefits both our community and our customers.
            </p>
            
        </div>  
        </div>

      <Footer />
    </>
  );
}

export default AboutUsPage;
