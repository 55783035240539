import WetOffSawTimber from "../Images/Wet-OffSawTimber.jpg";
import Cradles from "../Images/Cradles.png";
import Product3Image from "../Images/Products3.jpg";
import { Link } from "react-router-dom";

function Products() {
  return (
    <div class="product-body">
      <div class="products-container">
        <h1>Products</h1>
        <div class="row">
          <div class="product-card">
            <img src={WetOffSawTimber} alt="Product 1" />
            <p class="product-description product-1">
              Wet-off saw timber for the pallet manufacturers.
            </p>
          </div>
          <div class="product-card">
            <p class="product-description product-2">
            Skids for the shipping industry using heat-treated timber.
            </p>
            <img src={Cradles} alt="Product 2" />
          </div>

          <div class="product-card">
            <img src={Product3Image} alt="Product 3" />
            <p class="product-description product-3">
              Customized wooden products according to clients’ specifications
            </p>
          </div>
        </div>

        <Link to="/product">
      <button className="view-more-btn">View More</button>
    </Link>
      </div>
    </div>
  );
}

export default Products;
