import FooterLogo from "../Images/FooterLogo.png";
import LocationIcon from "../Images/Location-icon.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function Footer() {
  return (
    <footer>
      <div class="footer-content">
        <div class="footer-logo">
        <Link to="/"> <img src={FooterLogo} alt="" /> </Link>
        </div>
          
        <div class="address">
          {/* <p>
            <img src={LocationIcon} alt="Location Icon" />
            Richmond
          </p> */}
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2892.356659218285!2d30.272656475546846!3d-29.86382847501503!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDUxJzQ5LjgiUyAzMMKwMTYnMzAuOCJF!5e1!3m2!1sen!2sza!4v1700230704331!5m2!1sen!2sza" 
          width="300" 
          height="50" 
          style={{border:0}} 
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>

      <hr />
      <div class="footer-info">
        <div class="info-text">
          <p>
            info@akilitimber.com <br /> +27 87 702 4381{" "}
          </p>
        </div>
        <div class="info-link">
          <ul class="footer-links">
            <li>
              <Link to="/">
                {" "}
                <ScrollToTopOnMount />
                Home
              </Link>
            </li>

            <li>
              <Link to="/about">
                {" "}
                <ScrollToTopOnMount />
                About Us
              </Link>
            </li>

            <li>
              <Link to="/product">
                {" "}
                <ScrollToTopOnMount />
                Products
              </Link>
            </li>

            <li>
              <Link to="/contact">
                {" "}
                <ScrollToTopOnMount />
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div class="trading-hours">
          <p>
            Working Hours
            <br></br>Mon-Fri : 07h30 - 16h30
            <br></br>Sat: 07h30 - 12h00
          </p>
        </div>
      </div>
      <div class="copyright">
        <p>&copy; 2023 Akili Timber - All Right Reserved. </p>
      </div>
    </footer>
  );
}

export default Footer;
