import React from 'react'
import NavBar from '../components/NavBar'
import LandingPage from '../components/LandingPage'
import WhatWeDo from '../components/WhatWeDo'
import MissionAndVision from '../components/MissionAndVision'
import AboutUs from '../components/AboutUs'
import Products from '../components/Products'
import ContactUs from '../components/ContactUs'
import Footer from '../components/Footer'
function HomePage() {
  return (
    <>
    <body>
        <NavBar />
        <LandingPage />
        <WhatWeDo />
        <MissionAndVision />
        <AboutUs />
        <Products />
        <ContactUs />
    </body>
    <Footer />
    </>
  )
}

export default HomePage