import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const LandingPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      
    });
  }, []);

  return (
    <main>
      <div className="content" data-aos="fade-left">
        <h1 data-aos="fade-up">WELCOME TO AKILI TIMBER</h1>
        <h2 data-aos="fade-down" data-aos-duration="1500">
          Crafting Timber with Care | Exceeding Expectations
        </h2>
      </div>
    </main>
  );
};

export default LandingPage;

