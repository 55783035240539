import PhoneIcon from "../Images/phoneIcon.png";
import MenuBarSVG from "../Images/MenuBarSVG2.svg";
import logo from "../Images/Logo.jpeg";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";

function NavBar() {

  const [toggled, setToggled] = useState(false);
  const navlist = useRef(null)

  function hamburger_menu() {
    if (navlist.current.classList.contains("closed")) {
      navlist.current.classList.remove("closed");
      console.log("Shift back to Page");
    } else {
      navlist.current.classList.add("closed");
      console.log("Shift to far left");
    }
    }

  return (
    <header>
      <Link to="/"> 
        <img src={logo} alt="Akili Timber Logo" className="logo" />
      </Link>
      <div id="hamburger" onClick={() => hamburger_menu()}>
        <img alt="" src={MenuBarSVG} />
      </div>
      <div ref={navlist} id="navlisID" class="navlist closed">
        <ul class="header-list">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <Link to="/product">Products</Link>
          </li>
          <li>
          <Link to="/contact"> Contact Us</Link>
          </li>
        </ul>

        <div class="contact-info">
          <img src={PhoneIcon} alt="Akili timber Phone Icon" />
          +27 87 702 4381
        </div>
      </div>
    </header>
  );
}

export default NavBar;
