import PindiweImage from "../Images/PindiweImage2.1_BGEdRemoved.png";
import { Link } from "react-router-dom";
function AboutUs() {
  return (
    <div class="_container">
      <div class="Pindiwe-image" data-aos="fade-right">
        <img src={PindiweImage} alt="Pindiwe Image" />
      </div>
      <div class="about" data-aos="fade-left" style={{display:"flex"}}>
        <h1>About Us</h1>
        <p class="about-description">
        Akili Timber is your trusted sawmill in Richmond, KZN Midlands. Our strategic location just one hour away from Durban Harbor ensures swift turnarounds and hassle-free deliveries. 
        We pride ourselves on our extensive experience and commitment to quality. Our greatest strength lies in our skilled personnel, many of whom are residents of the local community. 
        We invite you to explore our world of timber craftsmanship and experience the quality and commitment that sets us apart. 

          
        </p>

        <Link to="/about">
          <button className="btn">Learn More...</button>
        </Link>
      </div>
    </div>
  );
}

export default AboutUs;
