import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./responsive.css";
import "./contactUs.css";
import HomePage from "./Pages/HomePage";
import AboutUsPage from "./Pages/AboutUsPage";
import ProductsPage from "./Pages/ProductsPage";
import ContactUsPage from "./Pages/ContactUsPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />}></Route>
        <Route path="/about" element={<AboutUsPage />}></Route>
        <Route path="/product" element={<ProductsPage />}></Route>
        <Route path="/contact" element={<ContactUsPage />}></Route>
      </Routes>
    </div>
  );
}

export default App;
