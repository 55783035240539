import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import phoneIcon from "../Images/phoneIcon.png"
import emailIcon from "../Images/emailIcon.png"

const ContactUsPage = () => {


  return (
    <>
      <NavBar />

      <div className="contactUs" style={{ backgroundColor: "black" }}>
        <div className="title">
          <h2>Need Help?---Contact Us</h2>
        </div>
        </div>

      <div className="contact-container" style={{ backgroundColor: "black" }}>
      <div className="phone">
        <div className="phone-icon">
          <img src={phoneIcon} alt="Phone Icon" />
        </div>
        <div className="phone-number">+27 87 702 4381</div>
      </div>
      <div className="email">
        <div className="email-icon">
          <img src={emailIcon} alt="Email Icon" />
        </div>
        <div className="email-info">info@akilitimber.com</div>
      </div>
    </div>
  

      <Footer />
    </>
  );
};

export default ContactUsPage;
