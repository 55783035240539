import React from 'react'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Product1 from "../Images/poultry-farmers.jpg";
import Product2 from "../Images/Cradles.png"
import Product3 from "../Images/Wooden-Box.jpg"
import Product4 from "../Images/Specialised pallet with kiln dried A-grade timber..jpg"
import Product5 from "../Images/Timber Cut to size.jpg"
import Product6 from "../Images/poultry.jpg"


function ProductsPage() {

  return (
     <>
      <NavBar />
    <div class="product-body">
    <div class="products-container">
      <h1>Products</h1>
      <div class="row">
          <div class="product-card">
              <img src= {Product6} alt="" />
              <p class="product-description product-1">Manufacturing of cradles for the shipping industry using heat-treated timber.</p>
          </div>
  
          <div class="product-card">
              <p class="product-description product-2">Skids for the shipping industry using heat-treated timber.</p>
              <img src={Product2} alt="" />
          </div>
  
          <div class="product-card">
              <img src={Product4} alt="" />
              <p class="product-description product-3">Specialised pallet with kiln dried A-grade timber.</p>
          </div>
        
      </div>
      <div class="row row2">
        <div class="product-card">
            <img src={Product3} alt="" />
            <p class="product-description product-1">Customized wooden products according to clients’ specifications.</p>
        </div>

        <div class="product-card">
            <p class="product-description product-2">Timber cut into different sizes.</p>
            <img src={Product5} alt="" />
        </div>
        <div class="product-card">
            <img src={Product1} alt= "" />
            <p class="product-description product-3">Shavings for poultry farmers</p>
        </div>
  </div>
  </div>
  </div>
        <Footer />
  </>
  );
}

export default ProductsPage;