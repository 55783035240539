
import stock from "../Images/Stock.png"
function WhatWeDo(){
 return (
    <div class="container">
    <div class="description"  data-aos="fade-right">
      <h1>What We Do</h1>
      
                Our primary focus is on the manufacturing of heavy-duty cradles and pallets designed specifically for the export industry. We understand the unique needs of the export industry and are dedicated to meeting those needs with precision and care.
                All material not used in the manufacturing of cradles goes into the manufacturing of pallets and other wooden materials. In addition to our core offerings, we provide value-added services that go the extra mile to benefit our clients. Our team works diligently to meet and exceed industry. 
                We have heat treatment facilities on site for the required treatments.


    </div>
    <div class="stock-image" data-aos="fade-left">
        <img src={stock} alt="Timber Image" />
    </div>
  </div>
  )
}

export default WhatWeDo;